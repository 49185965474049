import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';
import MirrorLoader from '../../../Composed/Loader/MirrorLoader';

import './StudyDeckDelete.scss';

const StudyDeckDelete = ({ children, studyDeck, deleteStudyDeck, queryString }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState('');

  const handleAlertOpen = e => {
    e.preventDefault();

    setShowAlert(true);
    setDeleting(false)
    setError('');
  }

  const handleDelete = e => {
    e.preventDefault();

    setDeleting(true);
    setError('');

    deleteStudyDeck(studyDeck.id, queryString).then(res => {
      setDeleting(false);

      if (res?.errors?.error) {
        setError(res.errors.error);
      } else {
        setShowAlert(false);
      }
    });
  }

  return (
    <div className="Study-Deck-Delete-Container">
      {
        showAlert
        && (
          <div className="Alert">
            <div className="modal">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="Title">Delete Collection</div>

                  <ButtonBox
                    className="Close-Btn"
                    src="close.svg"
                    onClick={() => setShowAlert(false)}
                  />
                </div>

                <div className="modal-body">
                  <div className="Instructions">
                    Are you sure you want to delete collection "<strong>{studyDeck.name}</strong>"?
                  </div>

                  {deleting && <MirrorLoader message="Deleting Collection!" />}

                  {error && <div className="Flash-Message Danger">{error}</div>}
                </div>

                <div className="modal-footer">
                  <ButtonBox
                    className="Submit-Btn Primary-Btn Red-Button"
                    text={<span className="Primary-Btn-Text">Delete Collection</span>}
                    onClick={e => handleDelete(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      }

      <div
        className="Child"
        onClick={(e) => handleAlertOpen(e)}
      >
        {children}
      </div>
    </div>
  )
}

StudyDeckDelete.propTypes = {
  children: PropTypes.node.isRequired,
  studyDeck: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  deleteStudyDeck: PropTypes.func.isRequired,
  queryString: PropTypes.string.isRequired,
}

export default StudyDeckDelete;
