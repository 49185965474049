import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';
import TextBox from '../../../Basic/TextBox/TextBox';

import { gaEvent } from '../../../../Util/Helper/GoogleUtil';
import { shuffle } from '../../../../Util/Helper/GenericUtil';

import './StudyDeckPlay.scss';

const StudyDeckPlay = ({ children, studyDeck }) => {
  const [showPlayer, setShowPlayer] = useState(false);
  const [settings, setSettings] = useState({});
  const [material, setMaterial] = useState({});
  const [cardIndex, setCardIndex] = useState(0);
  const [studyStats, setStudyStats] = useState({});
  const [autoPlay, setAutoPlay] = useState(false);
  const [shuffling, setShuffling] = useState(false);

  const autoPlayRef = useRef(false);

  useEffect(() => {
    if (autoPlay) {
      handleAutoPlayCardFront();
    }
  }, [autoPlay, cardIndex]);

  const handlePlayerOpen = e => {
    e.preventDefault();

    setShowPlayer(true);
    setSettings({ mode: 'presentation' });
    setMaterial({ cards: Object.values(studyDeck.studyCards || {}), total: Object.keys(studyDeck.studyCards || {}).length });
    setCardIndex(0);
    setStudyStats({ review: 0, got: 0, completed: false });
    setAutoPlay(false);
    setShuffling(false);
  }

  const handleDefaultSide = () => {
    const card = document.querySelectorAll('.Card-Flip-Area')[0];

    card.setAttribute('style', 'transform: none');
    card.setAttribute('style', 'transition: 0.1s');
  }

  const handleSwitchModeButtonClick = e => {
    e.preventDefault();

    gaEvent('study_deck_mode_change', { study_deck_id: studyDeck.id });
    handleDefaultSide();

    setSettings({ mode: (settings.mode === 'presentation' ? 'study' : 'presentation') });
    setCardIndex(0);
    setStudyStats({ review: 0, got: 0, completed: false });
  }

  const handleReset = (e) => {
    e.preventDefault();

    gaEvent('study_deck_reset', { study_deck_id: studyDeck.id });
    handleDefaultSide();

    setCardIndex(0);
    setStudyStats({ review: 0, got: 0, completed: false });
  }

  const handleAutoPlayStart = () => {
    gaEvent('study_deck_autoplay', { study_deck_id: studyDeck.id });
    handleDefaultSide();

    setAutoPlay(true);
    autoPlayRef.current = true;
  }

  const handleAutoPlayStop = () => {
    setAutoPlay(false);
    autoPlayRef.current = false;
  }

  const handleAutoPlayCardFront = async () => {
    if (!autoPlayRef.current) {
      return
    }

    setTimeout(() => handleAutoPlayCardBack(), 3000);
  }

  const handleAutoPlayCardBack = () => {
    if (!autoPlayRef.current) {
      return
    }

    document.querySelectorAll('.Card-Flip-Btn')[0].click();
    setTimeout(() => handleAutoPlayNext(), 3000);
  }

  const handleAutoPlayNext = () => {
    if (!autoPlayRef.current) {
      return
    }

    if (cardIndex < (material.total - 1)) {
      document.querySelectorAll('.Navigation-Middle .Number')[0].classList.add('Highlight');
      setTimeout(() => document.querySelectorAll('.Navigation-Middle .Number')[0].classList.remove('Highlight'), 300);
      document.querySelectorAll('.Next-Card-Btn')[0].click();
    } else {
      handleAutoPlayStop();
    }
  }

  const handleCardsShuffle = e => {
    e.preventDefault();

    gaEvent('study_deck_shuffle_cards', { study_deck_id: studyDeck.id });
    handleDefaultSide();

    if (shuffling) {
      setMaterial({ ...material, cards: Object.values(studyDeck.studyCards || {}) });
      setShuffling(false);
    } else {
      setMaterial({ ...material, cards: shuffle(JSON.parse(JSON.stringify(Object.values(studyDeck.studyCards || {})))) });
      setShuffling(true);
    }

    setCardIndex(0);
    setStudyStats({ review: 0, got: 0, completed: false });
  }

  const handleCardFlip = e => {
    e.preventDefault();

    gaEvent('study_deck_flip_card', { study_deck_id: studyDeck.id });

    const card = document.querySelectorAll('.Card-Flip-Area')[0];
    card.setAttribute('style', 'transform: rotateY(180deg)');
  }

  const handleCardFlipBack = e => {
    e.preventDefault();

    gaEvent('study_deck_flip_card_back', { study_deck_id: studyDeck.id });

    const card = document.querySelectorAll('.Card-Flip-Area')[0];
    card.setAttribute('style', 'transform: none');
  }

  const handleCardPrevious = e => {
    e.preventDefault();

    gaEvent('study_deck_previous_card', { study_deck_id: studyDeck.id });
    handleDefaultSide();

    if (cardIndex !== 0) {
      setCardIndex(cardIndex - 1);
    } else {
      setCardIndex(material.total - 1);
    }
  }

  const handleCardNext = e => {
    e.preventDefault();

    gaEvent('study_deck_next_card', { study_deck_id: studyDeck.id });
    handleDefaultSide();

    if (cardIndex < (material.total - 1)) {
      setCardIndex(cardIndex + 1);
    } else {
      setCardIndex(0);
    }
  }

  const handleKeepPracticing = e => {
    e.preventDefault();

    gaEvent('study_deck_practice_card', { study_deck_id: studyDeck.id });
    handleDefaultSide();

    if (cardIndex < (material.total - 1)) {
      setStudyStats({ ...studyStats, review: (studyStats.review + 1) });
      setCardIndex(cardIndex + 1);
    } else {
      setStudyStats({ ...studyStats, review: (studyStats.review + 1), completed: true });
    }
  }

  const handleIKnowThis = e => {
    e.preventDefault();

    gaEvent('study_deck_know_card', { study_deck_id: studyDeck.id });
    handleDefaultSide();

    if (cardIndex < (material.total - 1)) {
      setStudyStats({ ...studyStats, got: (studyStats.got + 1) });
      setCardIndex(cardIndex + 1);
    } else {
      setStudyStats({ ...studyStats, got: (studyStats.got + 1), completed: true });
    }
  }

  const renderControlButtons = () => {
    return (
      <div className="Control-Buttons">
        <ButtonBox
          className={`ButtonBox-FlashCard Normal-Btn Switch-Mode-Btn ${autoPlay ? 'Autoplaying' : ''}`}
          text={settings.mode === 'presentation' ? 'Switch to study mode' : 'Switch to presentation mode'}
          onClick={e => handleSwitchModeButtonClick(e)}
        />

        {
          (settings.mode === 'study')
          && (
            <ButtonBox
              className="ButtonBox-FlashCard Normal-Btn Reset-Study-Btn"
              text={<span>Reset <i className="fa fa-rotate-left" /></span>}
              onClick={e => handleReset(e)}
            />
          )
        }

        {
          (settings.mode === 'presentation')
          && (
            <ButtonBox
              className="ButtonBox-FlashCard Normal-Btn Auto-Play-Btn"
              text={<span>Auto Play <i className={`fas ${autoPlay ? 'fa-stop' : 'fa-play'}`} /></span>}
              onClick={e => {
                e.preventDefault();
                (autoPlay ? handleAutoPlayStop() : handleAutoPlayStart());
              }}
            />
          )
        }

        {
          !studyStats.completed
          && (
            <ButtonBox
              className={`ButtonBox-FlashCard Normal-Btn Shuffle-Btn ${autoPlay ? 'Autoplaying' : ''}`}
              text={<span>{shuffling ? 'Original' : 'Shuffle'} <i className="fa fa-shuffle" /></span>}
              onClick={e => handleCardsShuffle(e)}
            />
          )
        }
      </div>
    );
  }

  const renderCurrentCard = () => {
    const currentCard = material.cards[cardIndex];

    if (!studyStats.completed) {
      return (
        <div className="Study-Card">
          <div className="flip-card">
            <div className="flip-card-inner Card-Flip-Area">
              <div className="flip-card-front">
                <div className="flip-card-front-buttons">
                  <ButtonBox
                    className={`ButtonBox-FlashCard-icon Card-Flip-Btn ${autoPlay ? 'Autoplaying' : ''}`}
                    text={<i className="fa-solid fa-arrows-rotate" />}
                    onClick={e => handleCardFlip(e)}
                  />
                </div>
                <div className="flip-card-front-text">
                  <TextBox text={currentCard.front_text} tag="h1" />
                </div>
              </div>
              <div className="flip-card-back">
                <div className="flip-card-front-buttons">
                  <ButtonBox
                    className={`ButtonBox-FlashCard-icon Card-Flip-Btn ${autoPlay ? 'Autoplaying' : ''}`}
                    text={<i className="fa-solid fa-arrows-rotate" />}
                    onClick={e => handleCardFlipBack(e)}
                  />
                </div>
                <div className="flip-card-front-text">
                  <TextBox text={currentCard.back_text} tag="h1" />
                </div>
              </div>
            </div>
          </div>

          <div className="Cards-Progress">
            <div className="Cards-Progress-Filler" style={{ width: `${Math.ceil((cardIndex + 1) / material.total * 100)}%` }} />
          </div>
        </div>
      );
    }

    return (
      <div className="Study-Card">
        <div className="flip-card">
          <div className="flip-card-inner Card-Flip-Area">
            <div className="flip-card-front">
              <div className="flip-card-front-text">
                {
                  studyStats.got === material.total
                    ? (
                      <div className="Result">
                        <TextBox text="Congratulations!!! You have mastered all the words!" tag="h4" />
                        <i className="fa fa-face-grin-stars fa-5x Happy" />
                      </div>
                    )
                    : (
                      <div className="Result">
                        <TextBox text={`Needs Review: ${studyStats.review}`} tag="h4" />
                        <TextBox text={`Mastered words: ${studyStats.got}`} tag="h4" />
                        <i className="fa-solid fa-face-frown fa-5x Sad" />
                      </div>
                    )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const renderNavigationButtons = () => {
    if (settings.mode === 'presentation' && !studyStats.completed) {
      return (
        <div className="Study-Card-Navigation">
          <div className="Navigation-Left">
            <ButtonBox
              className={`ButtonBox-FlashCard Normal-Btn Previous-Card-Btn ${autoPlay ? 'Autoplaying' : ''}`}
              text={<i className="fa fa-play" />}
              onClick={e => handleCardPrevious(e)}
            />
          </div>
          <div className="Navigation-Middle">
            <TextBox className="Number" text={`Card ${cardIndex + 1} of ${material.total}`} />
          </div>
          <div className="Navigation-Right">
            <ButtonBox
              className={`ButtonBox-FlashCard Normal-Btn Next-Card-Btn ${autoPlay ? 'Autoplaying' : ''}`}
              text={<i className="fa fa-play" />}
              onClick={e => handleCardNext(e)}
            />
          </div>
        </div>
      );
    }

    if (settings.mode === 'study' && !studyStats.completed) {
      return (
        <div className="Study-Card-Navigation">
          <div className="Navigation-Left">
            <ButtonBox
              className="ButtonBox-FlashCard Review-Btn"
              text="Keep reviewing"
              onClick={e => handleKeepPracticing(e)}
            />
            <div className="Review-Count">
              <div className="Count">{studyStats.review}</div>
            </div>
          </div>
          <div className="Navigation-Middle">
            <TextBox className="Number" text={`Card ${cardIndex + 1} of ${material.total}`} />
          </div>
          <div className="Navigation-Right">
            <ButtonBox
              className="ButtonBox-FlashCard Got-Btn"
              text="I got this"
              onClick={e => handleIKnowThis(e)}
            />
            <div className="Got-Count">
              <div className="Count">{studyStats.got}</div>
            </div>
          </div>
        </div>
      );
    }

    if (studyStats.completed) {
      return (
        <div className="Study-Card-Navigation">
          <div className="Navigation-Middle">
            <ButtonBox
              className="ButtonBox-FlashCard Normal-Btn"
              text={studyStats.got === material.total ? 'Finish!' : 'Keep Reviewing'}
              onClick={e => handleSwitchModeButtonClick(e)}
            />
          </div>
        </div>
      );
    }
  }

  return (
    <div className="Study-Deck-Play-Container">
      {
        showPlayer
        && (
          <div className="Player">
            <div className="modal">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="Title">Flashcards</div>

                  <ButtonBox
                    className="Close-Btn"
                    src="close.svg"
                    onClick={() => setShowPlayer(false)}
                  />
                </div>

                <div className="modal-body">
                  {
                    Object.keys(studyDeck.studyCards || {}).length ? (
                      <div className="Study-Cards">
                        {renderControlButtons()}
                        {renderCurrentCard()}
                        {renderNavigationButtons()}
                      </div>
                    ) : (
                      <div className="No-Cards Flash-Message Danger">
                        There are no flashcards in this collection.
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        )
      }

      <div
        className="Child"
        onClick={e => handlePlayerOpen(e)}
      >
        {children}
      </div>
    </div>
  )
}

StudyDeckPlay.propTypes = {
  children: PropTypes.node.isRequired,
  studyDeck: PropTypes.shape({
    id: PropTypes.number.isRequired,
    studyCards: PropTypes.shape({
      id: PropTypes.number,
      front_text: PropTypes.string,
      back_text: PropTypes.string,
    }),
  }),
}

export default StudyDeckPlay;
