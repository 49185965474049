import { combineReducers } from 'redux';

import userResources from './UserEntities/UserResourcesReducer';
import studyDecks from './UserEntities/StudyDecksReducer';
import profileCompletion from './UserEntities/ProfileCompletionReducer';

export default combineReducers({
  userResources,
  studyDecks,
  profileCompletion,
});
