import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import WorkplaceSwitch from '../../NavBars/WorkplaceSwitch/WorkplaceSwitch';
import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import ImageBox from '../../Basic/ImageBox/ImageBox';

import { isBusinessLicense } from '../../../Util/Helper/LicenseUtil';

import './UserSideBar.css';

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  currentWorkplace: state.navigation.currentWorkplace
});

const UserSideBar = ({ match, currentUser, currentWorkplace }) => {
  const path = match.params.path;
  const fullPath = window.location.hash;
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const handleCollapseToggle = () => {
    const SideBar = document.querySelectorAll('.User-Side-Bar');
    SideBar.forEach((sideBar) => {
      sideBar.classList.toggle('User-Side-Bar-Show');
    });
  }

  const collapseAfterClick = () => {
    const SideBar = document.querySelectorAll('.User-Side-Bar');
    SideBar.forEach((sideBar) => {
      sideBar.classList.remove('User-Side-Bar-Show');
    });
  }

  return (
    <div className="User-Side-Bar">
      <div className="User-Side-Bar-Switch">
        <WorkplaceSwitch />
      </div>

      <div className="User-Side-Bar-List">
        <Link
          to="/account/learn"
          onClick={() => collapseAfterClick()}
        >
          <div
            className={`User-Side-Bar-Item ${path === 'learn' ? 'User-Side-Bar-Item-Selected' : ''}`}
          >
            <ImageBox className="User-Side-Bar-Image" src="canopy-icon.png" />
            <span>HOME</span>
          </div>
        </Link>

        {
          currentUser.accessibleProducts.compliance[currentWorkplace.id]?.access &&
          <Link
            to="/account/dashboard/compliance"
            onClick={() => collapseAfterClick()}
          >
            <div
              className={`User-Side-Bar-Item ${fullPath === '#/account/dashboard/compliance' ? 'User-Side-Bar-Item-Selected' : ''}`}
            >
              <ImageBox className="User-Side-Bar-Image" src="canopy-icon.png" />
              <span>COMPLIANCE</span>
            </div>
          </Link>
        }

        <Link
          to="/account/dashboard/credential"
          onClick={() => collapseAfterClick()}
        >
          <div
            className={`User-Side-Bar-Item ${fullPath === '#/account/dashboard/credential' ? 'User-Side-Bar-Item-Selected' : ''}`}
          >
            <ImageBox className="User-Side-Bar-Image" src="canopy-icon.png" />
            <span>CREDENTIAL</span>
          </div>
        </Link>

        <Link
          to="/account/dashboard/learn"
          onClick={() => collapseAfterClick()}
        >
          <div
            className={`User-Side-Bar-Item ${fullPath === '#/account/dashboard/learn' ? 'User-Side-Bar-Item-Selected' : ''}`}
          >
            <ImageBox className="User-Side-Bar-Image" src="canopy-icon.png" />
            <span>LEARN</span>
          </div>
        </Link>

        <Link
          to="/account/profile"
          onClick={() => collapseAfterClick()}
          className="mb-20"
        >
          <div
            className={`User-Side-Bar-Item ${path === 'profile' ? 'User-Side-Bar-Item-Selected' : ''} Tour-Step-Sidebar-Profile-Link`}
          >
            <ImageBox className="User-Side-Bar-Image" src="canopy-icon.png" />
            <span>PROFILE</span>
          </div>
        </Link>

        {
          !isBusinessLicense(currentWorkplace?.license_type) &&
          <Link
            to="/account/learn/course_packages"
            onClick={() => collapseAfterClick()}
          >
            <div
              className={`User-Side-Bar-Item-2`}
            >
              <div className="Item">
                <i className="fa fa-plus"></i>
                <span className="ml-10">ADD PRODUCTS</span>
              </div>
            </div>
          </Link>
        }

        <Link
          to="/account/resource_library"
          onClick={() => collapseAfterClick()}
        >
          <div className="User-Side-Bar-Item-2 Resource-Button">
            <div className="Item">
              <span className="ml-10">RESOURCE LIBRARY</span>
            </div>
          </div>
        </Link>

        <Link
          to="/account/my_flashcards"
          onClick={() => collapseAfterClick()}
        >
          <div className="User-Side-Bar-Item-2 Study-Cards-Button">
            <div className="Item">
              <span className="ml-10">MY FLASHCARDS</span>
            </div>
          </div>
        </Link>
      </div>

      {
        isMobile &&
        <div className="SideBar-Collapse-Button">
            <ButtonBox
              className="ButtonBox-SideBar"
              text={<i className="fas fa-bars"></i>}
              onClick={() => handleCollapseToggle()}
            />
        </div>
      }
    </div>
  );
};

export default connect(mapStateToProps, null)(UserSideBar);
