import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';
import MirrorLoader from '../../../Composed/Loader/MirrorLoader';

import './StudyDeckForm.scss';

const StudyDeckForm = ({ children, studyDeck, createStudyDeck, updateStudyDeck, queryString }) => {
  const [showForm, setShowForm] = useState(false);
  const [studyDeckAttrs, setStudyDeckAttrs] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  const handleFormOpen = e => {
    e.preventDefault();

    setShowForm(true);
    setStudyDeckAttrs(
      studyDeck ? {
        name: studyDeck.name,
      } : {
        name: '',
      }
    );
    setSubmitting(false);
    setErrors({});
  }

  const handleSubmit = e => {
    e.preventDefault();

    if (studyDeckAttrs.name.length < 2) {
      return setErrors({ name: 'Name is too short (minimum is 2 characters)' });
    } else if (studyDeckAttrs.name.length > 100) {
      return setErrors({ name: 'Name is too long (maximum is 100 characters)' });
    } else {
      setErrors({});
    }

    setSubmitting(true);
    const studyDeckParams = Object.assign({}, studyDeckAttrs);

    if (studyDeck) {
      updateStudyDeck({ id: studyDeck.id, ...studyDeckParams }, queryString).then(res => handleResponse(res));
    } else {
      createStudyDeck(studyDeckParams, queryString).then(res => handleResponse(res));
    }
  }

  const handleResponse = res => {
    setSubmitting(false);

    if (res?.errors?.error) {
      setErrors({ response: res.errors.error });
    } else {
      setShowForm(false);
    }
  }

  return (
    <div className="Study-Deck-Form-Container">
      {
        showForm
        && (
          <div className="Form">
            <div className="modal">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="Title">{studyDeck ? 'Edit' : 'New'} Collection</div>

                  <ButtonBox
                    className="Close-Btn"
                    src="close.svg"
                    onClick={() => setShowForm(false)}
                  />
                </div>

                <div className="modal-body">
                  <div className="Instructions">
                    {
                      studyDeck
                        ? 'Update the fields below to edit your collection and keep your flashcards organized'
                        : 'Fill out the fields below to create your collection and start organizing your flashcards'
                    }
                  </div>

                  {submitting && <MirrorLoader message={studyDeck ? 'Updating Collection!' : 'Creating Collection!'} />}

                  <div className="Error-Container">
                    {
                      (Object.keys(errors).length > 0)
                      && (
                        <ul id="Error" className="list-disc pl-10">
                          {
                            Object.values(errors).map((error, idx) => (
                              <li key={`error-${idx}`} children={error} />
                            ))
                          }
                        </ul>
                      )
                    }
                  </div>

                  <div className="CreationForms-InputBox">
                    <input
                      className="InputBox-LogInBox"
                      placeholder="Name"
                      value={studyDeckAttrs.name}
                      onChange={e => setStudyDeckAttrs({ ...studyDeckAttrs, name: e.currentTarget.value })}
                    />
                  </div>
                </div>

                <div className="modal-footer">
                  <ButtonBox
                    className="Submit-Btn Primary-Btn"
                    text={<span className="Primary-Btn-Text">{studyDeck ? 'Update Collection' : 'Create Collection'}</span>}
                    onClick={e => handleSubmit(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      }

      <div
        className="Child"
        onClick={e => handleFormOpen(e)}
      >
        {children}
      </div>
    </div>
  )
}

StudyDeckForm.propTypes = {
  children: PropTypes.node.isRequired,
  studyDeck: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  createStudyDeck: PropTypes.func.isRequired,
  updateStudyDeck: PropTypes.func.isRequired,
  queryString: PropTypes.string.isRequired,
}

export default StudyDeckForm;
