import React from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { sample } from 'lodash';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import StudyCardForm from './StudyCardForm/StudyCardForm';
import StudyCardDelete from './StudyCardDelete/StudyCardDelete';
import StudyDeckPlay from '../StudyDeck/StudyDeckPlay/StudyDeckPlay';

import './StudyCard.scss';

const StudyCard = ({
  studyDeck, studyCards,
  createStudyCard, updateStudyCard, deleteStudyCard,
}) => {
  const history = useHistory();

  const colorCombo = [['#e0f3ff', '#bee5fe'], ['#d4fff7', '#9beadc'], ['#f0f0f0', '#cecece']];

  if (!studyDeck) {
    history.push('/account/my_flashcards/');
    return '';
  }

  const renderStudyCards = () => {
    return (
      <div className="Study-Cards">
        {
          Object.values(studyCards).map((studyCard, idx) => {
            const colors = sample(colorCombo);

            return (
              <div key={`study-card-${idx}`} className="Study-Card" style={{ '--pColor': colors[0], '--sColor': colors[1] }}>
                <div className="Content">
                  <div className="Text">
                    {studyCard.front_text}
                  </div>
                </div>

                <div className="Operations">
                  <StudyCardForm
                    studyDeck={studyDeck}
                    studyCard={studyCard}
                    updateStudyCard={updateStudyCard}
                  >
                    <ButtonBox
                      className="Edit-Study-Card"
                      text={<i className="fa fa-edit Icon" />}
                    />
                  </StudyCardForm>

                  <StudyCardDelete
                    studyDeck={studyDeck}
                    studyCard={studyCard}
                    deleteStudyCard={deleteStudyCard}
                  >
                    <ButtonBox
                      className="Delete-Study-Card"
                      icon="fa-trash Icon"
                    />
                  </StudyCardDelete>
                </div>
              </div>
            )
          })
        }
      </div>
    )
  }

  const renderNoData = () => (
    <div className="No-Data Flash-Message Danger">
      No flashcards were found in this collection!
    </div>
  )

  return (
    <div className="Study-Card-Container">
      <div className="Head">
        <Link to="/account/my_flashcards">
          <ButtonBox
            className="Back-To-Study-Deck"
            icon="fas fa-chevron-left"
            text="Back"
          />
        </Link>

        <div className="Title">
          Collection: {studyDeck.name}
        </div>

        <div className="Actions">
          <StudyDeckPlay studyDeck={studyDeck}>
            <ButtonBox
              className="Play-Study-Deck Primary-Btn"
              text={<span className="Primary-Btn-Text">Play Flashcards</span>}
            />
          </StudyDeckPlay>

          <StudyCardForm
            studyDeck={studyDeck}
            createStudyCard={createStudyCard}
          >
            <ButtonBox
              className="New-Study-Card Primary-Btn"
              text={<span className="Primary-Btn-Text">New Flashcard</span>}
            />
          </StudyCardForm>
        </div>
      </div>

      <div className="Body">
        {Object.keys(studyCards).length ? renderStudyCards() : renderNoData()}
      </div>
    </div>
  )
}

StudyCard.propTypes = {
  studyDeck: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }),
  studyCards: PropTypes.shape({
    front_text: PropTypes.string.isRequired,
  }),
  createStudyCard: PropTypes.func.isRequired,
  updateStudyCard: PropTypes.func.isRequired,
  deleteStudyCard: PropTypes.func.isRequired,
}

export default StudyCard;
