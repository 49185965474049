import { connect } from 'react-redux';

import StudyCard from './StudyCard';

import {
  createStudyCard,
  updateStudyCard,
  deleteStudyCard,
} from '../../../Actions/StudyCardActions';

const mapStateToProps = (state, { match }) => ({
  studyDeck: state.userEntities.studyDecks.data?.[match.params.studyDeckId],
  studyCards: (state.userEntities.studyDecks.data?.[match.params.studyDeckId]?.studyCards || {}),
});

const mapDispatchToProps = dispatch => ({
  createStudyCard: (studyDeckId, studyCardParams) => dispatch(createStudyCard(studyDeckId, studyCardParams)),
  updateStudyCard: (studyDeckId, studyCardParams) => dispatch(updateStudyCard(studyDeckId, studyCardParams)),
  deleteStudyCard: (studyDeckId, studyCardId) => dispatch(deleteStudyCard(studyDeckId, studyCardId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudyCard);
