import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { sample } from 'lodash';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import Loader from '../../Composed/Loader/Loader';
import Pagination from '../../Composed/Pagination/Pagination';
import StudyDeckForm from './StudyDeckForm/StudyDeckForm';
import StudyDeckDelete from './StudyDeckDelete/StudyDeckDelete';
import StudyDeckPlay from './StudyDeckPlay/StudyDeckPlay';

import './StudyDeck.scss';

const StudyDeck = ({
  studyDecks, clearStudyDecks, fetchStudyDecks,
  createStudyDeck, updateStudyDeck, deleteStudyDeck,
}) => {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [per, setPer] = useState(20);

  const colorCombo = [['#e0f3ff', '#bee5fe'], ['#d4fff7', '#9beadc'], ['#f0f0f0', '#cecece']];

  useEffect(() => {
    setLoading(true);
    clearStudyDecks();
    fetchStudyDecks(`page=${page}&per=${per}`).then(() => setLoading(false));
  }, [clearStudyDecks, fetchStudyDecks, page]);

  if (loading) {
    return <Loader message="Loading, Hang on!" />
  }

  const renderStudyDecks = () => {
    return (
      <div className="Study-Decks">
        {
          Object.values(studyDecks.data).map((studyDeck, idx) => {
            const colors = sample(colorCombo);

            return (
              <div key={`study-deck-${idx}`} className="Study-Deck" style={{ '--pColor': colors[0], '--sColor': colors[1] }}>
                <div className="Name">
                  {studyDeck.name}
                </div>

                <div className="Operations">
                  <Link to={`/account/my_flashcards/${studyDeck.id}`}>
                    <ButtonBox
                      className="Show-Study-Deck"
                      text={<i className="fa fa-eye Icon" />}
                    />
                  </Link>

                  <StudyDeckForm
                    studyDeck={studyDeck}
                    updateStudyDeck={updateStudyDeck}
                    queryString={`page=${page}&per=${per}`}
                  >
                    <ButtonBox
                      className="Edit-Study-Deck"
                      text={<i className="fa fa-edit Icon" />}
                    />
                  </StudyDeckForm>

                  <StudyDeckDelete
                    studyDeck={studyDeck}
                    deleteStudyDeck={deleteStudyDeck}
                    queryString={`page=${page}&per=${per}`}
                  >
                    <ButtonBox
                      className="Delete-Study-Deck"
                      text={<i className="fa fa-trash Icon" />}
                    />
                  </StudyDeckDelete>

                  <StudyDeckPlay studyDeck={studyDeck}>
                    <ButtonBox
                      className="Play-Study-Deck"
                      text={<i className="fa fa-play Icon" />}
                    />
                  </StudyDeckPlay>
                </div>
              </div>
            )
          })
        }
      </div>
    )
  }

  const renderNoData = () => (
    <div className="No-Data Flash-Message Danger">
      No collections were found!
    </div>
  )

  return (
    <div className="Study-Deck-Container">
      <div className="Head">
        <div className="Title">
          My Flashcard Collections
        </div>

        <div className="Actions">
          <StudyDeckForm
            createStudyDeck={createStudyDeck}
            queryString={`page=${page}&per=${per}`}
          >
            <ButtonBox
              className="New-Study-Deck Primary-Btn"
              text={<span className="Primary-Btn-Text">New Collection</span>}
            />
          </StudyDeckForm>
        </div>
      </div>

      <div className="Body">
        {studyDecks.data ? renderStudyDecks() : renderNoData()}
      </div>

      <div className="Foot">
        {
          studyDecks.total > 0
          && (
            <Pagination
              totalRecords={studyDecks.total}
              page={page}
              setPage={p => setPage(p)}
              per={per}
            />
          )
        }
      </div>
    </div>
  )
}

StudyDeck.propTypes = {
  studyDecks: PropTypes.shape({
    total: PropTypes.number.isRequired,
    data: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
  }),
  clearStudyDecks: PropTypes.func.isRequired,
  fetchStudyDecks: PropTypes.func.isRequired,
  createStudyDeck: PropTypes.func.isRequired,
  updateStudyDeck: PropTypes.func.isRequired,
  deleteStudyDeck: PropTypes.func.isRequired,
}

export default StudyDeck;
