import axios from './AxiosUtil';

export const createStudyCard = (studyDeckId, studyCardParams) => (
  axios.post(`/api/study_decks/${studyDeckId}/study_cards`, { study_card: studyCardParams }).then(
    res => res.data,
  )
);

export const updateStudyCard = (studyDeckId, studyCardParams) => (
  axios.put(`/api/study_decks/${studyDeckId}/study_cards/${studyCardParams.id}`, { study_card: studyCardParams }).then(
    res => res.data,
  )
);

export const deleteStudyCard = (studyDeckId, studyCardId) => (
  axios.delete(`/api/study_decks/${studyDeckId}/study_cards/${studyCardId}`).then(
    res => res.data,
  )
);
