import { omit } from 'lodash';

import {
  CLEAR_STUDY_DECKS,
  RECEIVE_STUDY_DECKS,
  RECEIVE_STUDY_DECK_ERRORS,
} from '../../Actions/StudyDeckActions';

import {
  RECEIVE_STUDY_CARD,
  DELETE_STUDY_CARD,
  RECEIVE_STUDY_CARD_ERRORS,
} from '../../Actions/StudyCardActions';

export default (state = {}, action) => {
  Object.freeze(state);

  switch (action.type) {
    case CLEAR_STUDY_DECKS:
      return {};
    case RECEIVE_STUDY_DECKS:
      return Object.assign({}, state, { total: action.payload.total, data: action.payload.studyDecks });
    case RECEIVE_STUDY_CARD:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.study_deck_id]: {
            ...state.data[action.payload.study_deck_id],
            studyCards: {
              ...state.data[action.payload.study_deck_id].studyCards,
              [action.payload.id]: action.payload,
            },
          },
        },
      }
    case DELETE_STUDY_CARD:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.study_deck_id]: {
            ...state.data[action.payload.study_deck_id],
            studyCards: omit(state.data[action.payload.study_deck_id].studyCards, [action.payload.id]),
          },
        },
      }
    default:
      return state;
  }
}
