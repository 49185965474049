import axios from './AxiosUtil';

export const fetchStudyDecks = (queryString) => {
  return axios.get(`/api/study_decks?${queryString}`).then(
    res => res.data,
  );
}

export const createStudyDeck = (studyDeckParams, queryString) => {
  return axios.post(`/api/study_decks?${queryString}`, { study_deck: studyDeckParams }).then(
    res => res.data,
  )
}

export const updateStudyDeck = (studyDeckParams, queryString) => {
  return axios.put(`/api/study_decks/${studyDeckParams.id}?${queryString}`, { study_deck: studyDeckParams }).then(
    res => res.data,
  )
}

export const deleteStudyDeck = (studyDeckId, queryString) => {
  return axios.delete(`/api/study_decks/${studyDeckId}?${queryString}`).then(
    res => res.data,
  )
}
