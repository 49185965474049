import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ButtonBox from '../../../Basic/ButtonBox/ButtonBox';
import MirrorLoader from '../../../Composed/Loader/MirrorLoader';

import './StudyCardForm.scss';

const StudyCardForm = ({ children, studyDeck, studyCard, createStudyCard, updateStudyCard }) => {
  const [showForm, setShowForm] = useState(false);
  const [studyCardAttrs, setStudyCardAttrs] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  const handleFormOpen = e => {
    e.preventDefault();

    setShowForm(true);
    setStudyCardAttrs(
      studyCard ? {
        frontText: studyCard.front_text,
        backText: studyCard.back_text,
      } : {
        frontText: '',
        backText: '',
      }
    );
    setSubmitting(false);
    setErrors({});
  }

  const validateForm = () => {
    const currentErrors = {};

    if (studyCardAttrs.frontText.length < 2) {
      currentErrors.front = 'Front text is too short (minimum is 2 characters)';
    }

    if (studyCardAttrs.frontText.length > 250) {
      currentErrors.front = 'Front text is too long (maximum is 250 characters)';
    }

    if (studyCardAttrs.backText.length < 2) {
      currentErrors.back = 'Back text is too short (minimum is 2 characters)';
    }

    if (studyCardAttrs.backText.length > 250) {
      currentErrors.back = 'Back text is too long (maximum is 250 characters)';
    }

    return currentErrors;
  }

  const handleSubmit = e => {
    e.preventDefault();

    const currentErrors = validateForm();
    setErrors(currentErrors);

    if (Object.keys(currentErrors).length === 0) {
      setSubmitting(true);
      const studyCardParams = {
        front_text: studyCardAttrs.frontText,
        back_text: studyCardAttrs.backText,
      }

      if (studyCard) {
        updateStudyCard(studyDeck.id, { id: studyCard.id, ...studyCardParams }).then(res => handleResponse(res));
      } else {
        createStudyCard(studyDeck.id, studyCardParams).then(res => handleResponse(res));
      }
    }
  }

  const handleResponse = res => {
    setSubmitting(false);

    if (res?.errors?.error) {
      setErrors({ response: res.errors.error });
    } else {
      setShowForm(false);
    }
  }

  return (
    <div className="Study-Card-Form-Container">
      {
        showForm
        && (
          <div className="Form">
            <div className="modal">
              <div className="modal-content">
                <div className="modal-header">
                  <div className="Title">{studyCard ? 'Edit' : 'New'} Flashcard</div>

                  <ButtonBox
                    className="Close-Btn"
                    src="close.svg"
                    onClick={() => setShowForm(false)}
                  />
                </div>

                <div className="modal-body">
                  <div className="Instructions">
                    {
                      studyCard
                        ? 'Update the fields below to edit your flashcard in this collection'
                        : 'Fill out the fields below to create your flashcard in this collection'
                    }
                  </div>

                  {submitting && <MirrorLoader message={studyCard ? 'Updating Flashcard!' : 'Creating Flashcard!'} />}

                  <div className="Error-Container">
                    {
                      (Object.keys(errors).length > 0)
                      && (
                        <ul id="Error" className="list-disc pl-10">
                          {
                            Object.values(errors).map((error, idx) => (
                              <li key={`error-${idx}`} children={error} />
                            ))
                          }
                        </ul>
                      )
                    }
                  </div>

                  <div className="CreationForms-InputBox">
                    <input
                      className="InputBox-LogInBox"
                      placeholder="Front Text"
                      value={studyCardAttrs.frontText}
                      onChange={e => setStudyCardAttrs({ ...studyCardAttrs, frontText: e.currentTarget.value })}
                    />
                  </div>

                  <div className="CreationForms-InputBox">
                    <input
                      className="InputBox-LogInBox"
                      placeholder="Back Text"
                      value={studyCardAttrs.backText}
                      onChange={e => setStudyCardAttrs({ ...studyCardAttrs, backText: e.currentTarget.value })}
                    />
                  </div>
                </div>

                <div className="modal-footer">
                  <ButtonBox
                    className="Submit-Btn Primary-Btn"
                    text={<span className="Primary-Btn-Text">{studyCard ? 'Update Flashcard' : 'Create Flashcard'}</span>}
                    onClick={e => handleSubmit(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        )
      }

      <div
        className="Child"
        onClick={e => handleFormOpen(e)}
      >
        {children}
      </div>
    </div>
  )
};

StudyCardForm.propTypes = {
  children: PropTypes.node.isRequired,
  studyDeck: PropTypes.shape({
    id: PropTypes.number.isRequired,
  }),
  studyCard: PropTypes.shape({
    id: PropTypes.number.isRequired,
    front_text: PropTypes.string.isRequired,
    back_text: PropTypes.string.isRequired,
  }),
  createStudyCard: PropTypes.func.isRequired,
  updateStudyCard: PropTypes.func.isRequired,
}

export default StudyCardForm;
