import React, { useEffect, useState, useRef } from 'react';

import ButtonBox from '../../Basic/ButtonBox/ButtonBox';
import TextBox from '../../Basic/TextBox/TextBox';
import AudioPlayButton from '../../Quiz/AudioPlayButton/AudioPlayButton';

import { gaEvent } from '../../../Util/Helper/GoogleUtil';
import { shuffle } from '../../../Util/Helper/GenericUtil';

import './Flashcard.scss';

const Flashcard = ({ section }) => {
  const [settings, setSettings] = useState({ mode: 'presentation', front: 'spanish' });
  const [material, setMaterial] = useState({ cards: section.flashcards, total: section.flashcards.length });
  const [cardIndex, setCardIndex] = useState(0);
  const [studyStats, setStudyStats] = useState({ review: 0, got: 0, completed: false });
  const [autoPlay, setAutoPlay] = useState(false);
  const [shuffling, setShuffling] = useState(false);

  const autoPlayRef = useRef(false);

  useEffect(() => {
    if (section.id !== material.cards[0]?.section_id) {
      handleDefaultSide();

      setSettings({ mode: 'presentation', front: 'spanish' });
      setMaterial({ cards: section.flashcards, total: section.flashcards.length });
      setCardIndex(0);
      setStudyStats({ review: 0, got: 0, completed: false });
      setAutoPlay(false);

      autoPlayRef.current = false;
    }
  }, [section]);

  useEffect(() => {
    if (autoPlay) {
      handleAutoPlayCardFront();
    }
  }, [autoPlay, cardIndex]);

  const handleDefaultSide = () => {
    let card = document.querySelectorAll('.Card-Flip-Area')[0];

    card.setAttribute('style', 'transform: none');
    card.setAttribute('style', 'transition: 0.1s');
  }

  const handleSwitchModeButtonClick = (e) => {
    e.preventDefault();

    gaEvent('flashcard_mode_change', { section_id: section.id });
    handleDefaultSide();

    setSettings({ mode: (settings.mode === 'presentation' ? 'study' : 'presentation'), front: 'spanish' });
    setCardIndex(0);
    setStudyStats({ review: 0, got: 0, completed: false });
    setAutoPlay(false);

    autoPlayRef.current = false;
  }

  const handleReset = (e) => {
    e.preventDefault();

    gaEvent('flashcard_reset', { section_id: section.id });
    handleDefaultSide();

    setCardIndex(0);
    setStudyStats({ review: 0, got: 0, completed: false });
    setAutoPlay(false);

    autoPlayRef.current = false;
  }

  const handleAutoPlayStart = () => {
    gaEvent('flashcard_autoplay', { section_id: section.id });
    handleDefaultSide();

    setAutoPlay(true);
    autoPlayRef.current = true;
  }

  const handleAutoPlayStop = () => {
    setAutoPlay(false);
    autoPlayRef.current = false;
  }

  const delay = (duration) => (
    new Promise(resolve => setTimeout(resolve, duration))
  );

  const handleAutoPlayCardFront = async () => {
    if (!autoPlayRef.current) {
      return
    }

    const audioButton = document.querySelectorAll('.ButtonBox-AudioPlayButton')[0];
    if (audioButton) {
      await delay(1000);

      if (audioButton.querySelectorAll('i')[0].classList.contains('fa-play')) {
        audioButton.click();
      } else {
        setTimeout(() => handleAutoPlayCardBack(), 2000);
      }
    } else {
      setTimeout(() => handleAutoPlayCardBack(), 3000);
    }
  }

  const handleAutoPlayCardBack = () => {
    if (!autoPlayRef.current) {
      return
    }

    document.querySelectorAll('.Card-Flip-Btn')[0].click();
    setTimeout(() => handleAutoPlayNext(), 3000);
  }

  const handleAutoPlayNext = () => {
    if (!autoPlayRef.current) {
      return
    }

    if (cardIndex < (material.total - 1)) {
      document.querySelectorAll('.FlashCard-Number')[0].classList.add('Highlight');
      setTimeout(() => document.querySelectorAll('.FlashCard-Number')[0].classList.remove('Highlight'), 300);
      document.querySelectorAll('.Next-Card-Btn')[0].click();
    } else {
      handleAutoPlayStop();
    }
  }

  const handleCardsShuffle = (e) => {
    e.preventDefault();

    gaEvent('flashcard_shuffle_cards', { section_id: section.id });
    handleDefaultSide();

    if (shuffling) {
      setMaterial({ cards: section.flashcards, total: section.flashcards.length });
      setShuffling(false);
    } else {
      setMaterial({ cards: shuffle(JSON.parse(JSON.stringify(section.flashcards))), total: section.flashcards.length });
      setShuffling(true);
    }

    setCardIndex(0);
    setStudyStats({ review: 0, got: 0, completed: false });
    setAutoPlay(false);

    autoPlayRef.current = false;
  }

  const handleCardFlip = (e) => {
    e.preventDefault();

    gaEvent('flashcard_flip_card', { section_id: section.id });

    let card = document.querySelectorAll('.Card-Flip-Area')[0];
    card.setAttribute('style', 'transform: rotateY(180deg)');
  }

  const handleCardFlipBack = (e) => {
    e.preventDefault();

    gaEvent('flashcard_flip_card_back', { section_id: section.id });

    let card = document.querySelectorAll('.Card-Flip-Area')[0];
    card.setAttribute('style', 'transform: none');
  }

  const handleCardPrevious = (e) => {
    e.preventDefault();

    gaEvent('flashcard_previous_card', { section_id: section.id });
    handleDefaultSide();

    if (cardIndex !== 0) {
      setCardIndex(cardIndex - 1);
    } else {
      setCardIndex(material.total - 1);
    }
  }

  const handleCardNext = (e) => {
    e.preventDefault();

    gaEvent('flashcard_next_card', { section_id: section.id });
    handleDefaultSide();

    if (cardIndex < (material.total - 1)) {
      setCardIndex(cardIndex + 1);
    } else {
      setCardIndex(0);
    }
  }

  const handleKeepPracticing = (e) => {
    e.preventDefault();

    gaEvent('flashcard_practice_card', { section_id: section.id });
    handleDefaultSide();

    if (cardIndex < (material.total - 1)) {
      setStudyStats({ ...studyStats, review: (studyStats.review + 1) });
      setCardIndex(cardIndex + 1);
    } else {
      setStudyStats({ ...studyStats, review: (studyStats.review + 1), completed: true });
    }
  }

  const handleIKnowThis = (e) => {
    e.preventDefault();

    gaEvent('flashcard_know_card', { section_id: section.id });
    handleDefaultSide();

    if (cardIndex < (material.total - 1)) {
      setStudyStats({ ...studyStats, got: (studyStats.got + 1) });
      setCardIndex(cardIndex + 1);
    } else {
      setStudyStats({ ...studyStats, got: (studyStats.got + 1), completed: true });
    }
  }

  const renderControlButtons = () => {
    return (
      <div className="Control-Buttons">
        {
          (section.study_mode)
          && (
            <ButtonBox
              className={`ButtonBox-FlashCard Normal-Btn Switch-Mode-Btn ${autoPlay ? 'Autoplaying' : ''}`}
              text={settings.mode === 'presentation' ? 'Switch to study mode' : 'Switch to presentation mode'}
              onClick={(e) => handleSwitchModeButtonClick(e)}
            />
          )
        }

        {
          (section.study_mode && settings.mode === 'study')
          && (
            <ButtonBox
              className="ButtonBox-FlashCard Normal-Btn Reset-Study-Btn"
              text={<span>Reset <i className="fa fa-rotate-left" /></span>}
              onClick={(e) => handleReset(e)}
            />
          )
        }

        {
          (settings.mode === 'presentation')
          && (
            <ButtonBox
              className="ButtonBox-FlashCard Normal-Btn Auto-Play-Btn"
              text={<span>Auto Play <i className={`fas ${autoPlay ? 'fa-stop' : 'fa-play'}`} /></span>}
              onClick={(e) => {
                e.preventDefault();
                (autoPlay ? handleAutoPlayStop() : handleAutoPlayStart());
              }}
            />
          )
        }

        {
          !studyStats.completed
          && (
            <ButtonBox
              className={`ButtonBox-FlashCard Normal-Btn Shuffle-Btn ${autoPlay ? 'Autoplaying' : ''}`}
              text={<span>{shuffling ? 'Original' : 'Shuffle'} <i className="fa fa-shuffle" /></span>}
              onClick={(e) => handleCardsShuffle(e)}
            />
          )
        }
      </div>
    );
  }

  const renderCurrentCard = () => {
    let currentCard = material.cards[cardIndex];

    if (settings.front === 'spanish' && !studyStats.completed) {
      return (
        <div className="FlashCard-Cards">
          <div className="flip-card">
            <div className="flip-card-inner Card-Flip-Area">
              <div className="flip-card-front">
                <div className="flip-card-front-buttons">
                  {
                    currentCard.audio_url
                      ? <AudioPlayButton src={currentCard.audio_url} onPlayFinished={() => handleAutoPlayCardBack()} />
                      : <div />
                  }
                  <ButtonBox
                    className={`ButtonBox-FlashCard-icon Card-Flip-Btn ${autoPlay ? 'Autoplaying' : ''}`}
                    text={<i className="fa-solid fa-arrows-rotate" />}
                    onClick={(e) => handleCardFlip(e)}
                  />
                </div>
                <div className="flip-card-front-text">
                  <TextBox
                    text={currentCard.text}
                    tag="h1"
                  />
                </div>
              </div>
              <div className="flip-card-back">
                <div className="flip-card-front-buttons">
                  <div />
                  <ButtonBox
                    className={`ButtonBox-FlashCard-icon Card-Flip-Btn ${autoPlay ? 'Autoplaying' : ''}`}
                    text={<i className="fa-solid fa-arrows-rotate" />}
                    onClick={(e) => handleCardFlipBack(e)}
                  />
                </div>
                <div className="flip-card-front-text">
                  <TextBox
                    text={currentCard.back_text}
                    tag="h1"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="Cards-Progress">
            <div className="Cards-Progress-Filler" style={{ width: `${Math.ceil((cardIndex + 1) / material.total * 100)}%` }}>
            </div>
          </div>
        </div>
      );
    }

    if (settings.front !== 'spanish' && !studyStats.completed) {
      return (
        <div className="FlashCard-Cards">
          <div className="flip-card">
            <div className="flip-card-inner Card-Flip-Area">
              <div className="flip-card-front">
                <div className="flip-card-front-buttons">
                  <div />
                  <ButtonBox
                    className={`ButtonBox-FlashCard-icon Card-Flip-Btn ${autoPlay ? 'Autoplaying' : ''}`}
                    text={<i className="fa-solid fa-arrows-rotate" />}
                    onClick={(e) => handleCardFlip(e)}
                  />
                </div>
                <div className="flip-card-front-text">
                  <TextBox
                    text={currentCard.back_text}
                    tag="h1"
                  />
                </div>
              </div>
              <div className="flip-card-back">
                <div className="flip-card-front-buttons">
                  {
                    currentCard.audio_url
                      ? <AudioPlayButton src={currentCard.audio_url} onPlayFinished={() => handleAutoPlayCardBack()} />
                      : <div />
                  }
                  <ButtonBox
                    className={`ButtonBox-FlashCard-icon Card-Flip-Btn ${autoPlay ? 'Autoplaying' : ''}`}
                    text={<i className="fa-solid fa-arrows-rotate" />}
                    onClick={(e) => handleCardFlipBack(e)}
                  />
                </div>
                <div className="flip-card-front-text">
                  <TextBox
                    text={currentCard.text}
                    tag="h1"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (studyStats.completed) {
      return (
        <div className="FlashCard-Cards">
          <div className="flip-card">
            <div className="flip-card-inner Card-Flip-Area">
              <div className="flip-card-front">
                <div className="flip-card-front-text">
                  {
                    studyStats.got === material.total
                    ? (
                      <div className="Result">
                        <TextBox
                          text="Congratulations!!! You have mastered all the words!"
                          tag="h4"
                        />
                        <i className="fa fa-face-grin-stars fa-5x Happy" />
                      </div>
                    )
                    : (
                      <div className="Result">
                        <TextBox
                          text={`Needs Review: ${studyStats.review}`}
                          tag="h4"
                        />
                        <TextBox
                          text={`Mastered words: ${studyStats.got}`}
                          tag="h4"
                        />
                        <i className="fa-solid fa-face-frown fa-5x Sad" />
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  const renderNavigationButtons = () => {
    if (settings.mode === 'presentation' && !studyStats.completed) {
      return (
        <div className="FlashCard-NavBar">
          <div className="FlashCard-NavBar-Left">
            <ButtonBox
              className={`ButtonBox-FlashCard Normal-Btn Previous-Card-Btn ${autoPlay ? 'Autoplaying' : ''}`}
              text={<i className="fa fa-play" />}
              onClick={(e) => handleCardPrevious(e)}
            />
          </div>
          <div className="FlashCard-NavBar-Middle">
            <TextBox className="FlashCard-Number" text={`Card ${cardIndex + 1} of ${material.total}`} />
          </div>
          <div className="FlashCard-NavBar-Right">
            <ButtonBox
              className={`ButtonBox-FlashCard Normal-Btn Next-Card-Btn ${autoPlay ? 'Autoplaying' : ''}`}
              text={<i className="fa fa-play" />}
              onClick={(e) => handleCardNext(e)}
            />
          </div>
        </div>
      );
    }

    if (settings.mode === 'study' && !studyStats.completed) {
      return (
        <div className="FlashCard-NavBar">
          <div className="FlashCard-NavBar-Left">
            <ButtonBox
              className="ButtonBox-FlashCard Review-Btn"
              text="Keep reviewing"
              onClick={(e) => handleKeepPracticing(e)}
            />
            <div className="Review-Count">
              <div className="Count">{studyStats.review}</div>
            </div>
          </div>
          <div className="FlashCard-NavBar-Middle">
            <TextBox className="FlashCard-Number" text={`Card ${cardIndex + 1} of ${material.total}`} />
          </div>
          <div className="FlashCard-NavBar-Right">
            <ButtonBox
              className="ButtonBox-FlashCard Got-Btn"
              text="I got this"
              onClick={(e) => handleIKnowThis(e)}
            />
            <div className="Got-Count">
              <div className="Count">{studyStats.got}</div>
            </div>
          </div>
        </div>
      );
    }

    if (studyStats.completed) {
      return (
        <div className="FlashCard-NavBar">
          <div className="FlashCard-NavBar-Middle">
            <ButtonBox
              className="ButtonBox-FlashCard Normal-Btn"
              text={studyStats.got === material.total ? 'Finish!' : 'Keep Reviewing'}
              onClick={(e) => handleSwitchModeButtonClick(e)}
            />
          </div>
        </div>
      );
    }
  }

  return (
    <div className="FlashCard">
      {renderControlButtons()}
      {renderCurrentCard()}
      {renderNavigationButtons()}
    </div>
  );
}

export default Flashcard;
