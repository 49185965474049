import * as APIUtil from '../Util/StudyCardApiUtil';
import ErrorResponseUtil from '../Util/ErrorResponseUtil';

export const RECEIVE_STUDY_CARD = 'RECEIVE_STUDY_CARD';
export const DELETE_STUDY_CARD = 'DELETE_STUDY_CARD';
export const RECEIVE_STUDY_CARD_ERRORS = 'RECEIVE_STUDY_CARD_ERRORS';

export const receiveStudyCard = payload => ({
  type: RECEIVE_STUDY_CARD,
  payload,
});

export const receiveDeleteStudyCard = payload => ({
  type: DELETE_STUDY_CARD,
  payload,
});

export const receiveErrors = errors => ({
  type: RECEIVE_STUDY_CARD_ERRORS,
  errors,
});

export const createStudyCard = (studyDeckId, studyCardParams) => dispatch => (
  APIUtil.createStudyCard(studyDeckId, studyCardParams).then(
    payload => dispatch(receiveStudyCard(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);

export const updateStudyCard = (studyDeckId, studyCardParams) => dispatch => (
  APIUtil.updateStudyCard(studyDeckId, studyCardParams).then(
    payload => dispatch(receiveStudyCard(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);

export const deleteStudyCard = (studyDeckId, studyCardId) => dispatch => (
  APIUtil.deleteStudyCard(studyDeckId, studyCardId).then(
    payload => dispatch(receiveDeleteStudyCard(payload)),
    errors => dispatch(receiveErrors(ErrorResponseUtil(errors))),
  )
);
