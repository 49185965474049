import { connect } from 'react-redux';

import StudyDeck from './StudyDeck';

import {
  clearStudyDecks,
  fetchStudyDecks,
  createStudyDeck,
  updateStudyDeck,
  deleteStudyDeck,
} from '../../../Actions/StudyDeckActions';

const mapStateToProps = state => ({
  studyDecks: state.userEntities.studyDecks,
});

const mapDispatchToProps = dispatch => ({
  clearStudyDecks: () => dispatch(clearStudyDecks()),
  fetchStudyDecks: (queryString) => dispatch(fetchStudyDecks(queryString)),
  createStudyDeck: (studyDeckParams, queryString) => dispatch(createStudyDeck(studyDeckParams, queryString)),
  updateStudyDeck: (studyDeckParams, queryString) => dispatch(updateStudyDeck(studyDeckParams, queryString)),
  deleteStudyDeck: (studyDeckId, queryString) => dispatch(deleteStudyDeck(studyDeckId, queryString)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StudyDeck);
