import * as APIUtil from '../Util/StudyDeckApiUtil';
import ErrorResponseUtil from '../Util/ErrorResponseUtil';

export const CLEAR_STUDY_DECKS = 'CLEAR_STUDY_DECKS';
export const RECEIVE_STUDY_DECKS = 'RECEIVE_STUDY_DECKS';
export const RECEIVE_STUDY_DECK_ERRORS = 'RECEIVE_STUDY_DECK_ERRORS';

export const clearStudyDecks = () => ({
  type: CLEAR_STUDY_DECKS,
});

export const receiveStudyDecks = payload => ({
  type: RECEIVE_STUDY_DECKS,
  payload,
});

export const receiveStudyDeckErrors = errors => ({
  type: RECEIVE_STUDY_DECK_ERRORS,
  errors,
});

export const fetchStudyDecks = queryString => dispatch => (
  APIUtil.fetchStudyDecks(queryString).then(
    payload => dispatch(receiveStudyDecks(payload)),
    errors  => dispatch(receiveStudyDeckErrors(ErrorResponseUtil(errors))),
  )
);

export const createStudyDeck = (studyDeckParams, queryString) => dispatch => (
  APIUtil.createStudyDeck(studyDeckParams, queryString).then(
    payload => dispatch(receiveStudyDecks(payload)),
    errors  => dispatch(receiveStudyDeckErrors(ErrorResponseUtil(errors))),
  )
);

export const updateStudyDeck = (studyDeckParams, queryString) => dispatch => (
  APIUtil.updateStudyDeck(studyDeckParams, queryString).then(
    payload => dispatch(receiveStudyDecks(payload)),
    errors  => dispatch(receiveStudyDeckErrors(ErrorResponseUtil(errors))),
  )
);

export const deleteStudyDeck = (studyDeckId, queryString) => dispatch => (
  APIUtil.deleteStudyDeck(studyDeckId, queryString).then(
    payload => dispatch(receiveStudyDecks(payload)),
    errors  => dispatch(receiveStudyDeckErrors(ErrorResponseUtil(errors))),
  )
);
